import React, { useState, useEffect } from 'react';
import '../styling/productpage.css';
import close_svg from '../../tamplates/images/closes.svg';
import { useNavigate } from 'react-router-dom';

function ProductPage({ product, onClose }) {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [buttonText, setButtonText] = useState('Place Order');
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [currentAuthDiv, setCurrentAuthDiv] = useState('LogIn');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
    };

    const closePopup = () => {
        setCurrentImageIndex(null);
    };

    useEffect(() => {
        if (product && product.images && product.images.length > 0) {
            setSelectedImageIndex(0);
        }
    }, [product]);

    const imgSrc = product && product.images ? product.images[selectedImageIndex] : '';

    const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
    };

    const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + product.images.length) % product.images.length);
    };

    const handlePlaceOrderClick = () => {
        const sessionToken = JSON.parse(localStorage.getItem('sessionToken'));

        if (sessionToken && sessionToken.expiryTime > Date.now()) {
            const userID = localStorage.getItem('userID');
            const productURL = product.product_page; // Use the product page URL

            setButtonText('Adding...');
            //https://webenginegptai-f6919d4667cb.herokuapp.com/api/user-product/
            fetch('http://127.0.0.1:8000/api/user-product/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ UserID: userID, ProductID: productURL })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setButtonText('Added');

                setTimeout(() => {
                    setButtonText('Place Order');
                }, 2000);
            })
            .catch(error => {
                console.error('Error:', error);
                setButtonText('Place Order');
            });
        } else {
            setOverlayVisible(true);
        }
    };

    const handleCloseOverlay = () => {
        setOverlayVisible(false);
    };

    const handleLogin = async () => {
        try {
            const response = await fetch('https://webenginegptai-f6919d4667cb.herokuapp.com/users/login_authorizer/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                if (data.status === "success") {
                    const expiryTime = Date.now() + 10000000000000; // Example expiry time
                    localStorage.setItem('sessionToken', JSON.stringify({ token: data.token, expiryTime }));
                    localStorage.setItem('userID', data.user_id);
                    setOverlayVisible(false);
                    setButtonText('Place Order');
                } else {
                    setErrorMessage(data.message || 'Incorrect password');
                }
            } else {
                setErrorMessage(data.message || 'User not found');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setErrorMessage('Failed to log in. Please try again.');
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (email === '' || password === '' || confirmPassword === '') {
            setMessage('All fields are required');
            return;
        }

        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        const userData = {
            email: email,
            password: password,
        };

        try {
            const response = await fetch('https://usbaiclient-8c59f2a693e9.herokuapp.com/users/register_user/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
                setCurrentAuthDiv('LogIn');
            } else {
                setMessage(data.message || 'Failed to register user');
            }
        } catch (error) {
            setMessage('Failed to connect to server');
        }
    };

    const renderLogin = () => (
        <div className='oi0hu9yt8g9hubihihuyvy'>
            <h1>LogIn</h1>
            <div className='jihugifguhiojppihugyft'>
                <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <br />
            </div>
            <div className='kjkjhgjhljoihugyf' onClick={(e) => { handleLogin(); handleCloseOverlay(e); }}>
                <a className='bhvjgciyioipufyut'>
                    LogIn
                </a>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
    );

    const renderSignup = () => (
        <div className='oi0hu9yt8g9hubihihuyvy'>
            <h1>SignUp</h1>
            <div className='jihugifguhiojppihugyft'>
                <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <input
                    type="password"
                    placeholder="Create Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <br />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <br />
            </div>
            <div className='kjkjhgjhljoihugyf' onClick={(e) => { e.preventDefault(); handleRegister(e); setCurrentAuthDiv('LogIn'); }}>
                <a className='bhvjgciyioipufyut'>
                    SignUp
                </a>
            </div>
            {message && <p className="error-message">{message}</p>}
        </div>
    );

    return (
        <div className='nhVi73tTX0C9kJpINv5bRc'>
            <div className='iggoifhgihperhg'>
                <div className='main-image-container'>
                    <img 
                        src={imgSrc}
                        alt="Selected"
                    />
                </div>
                <div className='oiugyftyguhiojpihugyeudjfougyho'>
                    <div className='huygutgyihohgyuftuyigfytu' onClick={handlePrevImage}>
                        <p>←</p>
                    </div>
                    
                    <div className='huygutgyihohgyuftuyigfytu' onClick={handleNextImage}>
                        <p>→</p>
                    </div>
                </div>
                <div className='mPq2tk7hvQwNXzGL34ZyKspuAOCYM'>
                    <div className="product-images">
                        {product.images.map((imgSrc, index) => (
                            <img
                                key={index}
                                src={imgSrc}
                                alt={`${product.name} image ${index + 1}`}
                                onClick={() => setSelectedImageIndex(index)}
                                style={{ cursor: 'pointer', border: selectedImageIndex === index ? '2px solid #000' : 'none' }}
                            />
                        ))}
                    </div>
                    <div className='productname_09392'>
                        <h1>{product.name}</h1>
                    </div>
                    <div className='pDLThbFaejlItMKV74d80N'>
                        <div className='pricing_0987467893'>
                            <p>{product.price}</p>
                        </div>
                        <div>
                            <div>
                                <p>Color: {product.color}</p>
                            </div>
                            <div>
                                <p>size: {product.product_size}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className='descriptionoftheproduct_948'>
                        <div>
                            <p>Om Product</p>
                        </div>
                        <div className='productdescription_30948392'>
                            <p>{product.description}</p>
                            <br />
                            <p>{product.description2}</p>
                        </div>

                        <div className="okdpihgr98g98rodjgkxgd004">
                            <div>
                                {product.images.map((imgSrc, index) => (
                                    <img
                                        key={index}
                                        src={imgSrc}
                                        alt={`${product.name} image ${index + 1}`}
                                        onClick={() => handleImageClick(index)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ))}
                            </div>
                        </div>

                        {currentImageIndex !== null && (
                            <div className="popup-overlay" onClick={closePopup}>
                                <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                                    <button className="close-button" onClick={closePopup}>X</button>
                                    <img
                                        src={product.images[currentImageIndex]}
                                        alt={`${product.name} full image ${currentImageIndex + 1}`}
                                        className="popup-image"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='ohueh8gh9oiehugbeodge'>
                <div className='pogysuitoeogheeruho'>
                    <div className='duhiygrrhsogiwjr9esrh' onClick={handlePlaceOrderClick}>
                        <a>{buttonText}</a>
                    </div>
                    <div className='duhiygrrhsogiwjr9esrh2' onClick={onClose}>
                        <a>Close</a>
                    </div>
                </div>
            </div>
            {overlayVisible && (
                <div className="fullpage-overlay">
                    <div className="auth-container">
                        <button className="close-button" onClick={handleCloseOverlay}>X</button>
                        {currentAuthDiv === 'LogIn' ? renderLogin() : renderSignup()}
                        <div>
                            <a onClick={() => setCurrentAuthDiv(currentAuthDiv === 'LogIn' ? 'SignUp' : 'LogIn')}>
                                {currentAuthDiv === "LogIn" ? "Don't have an account? Sign Up" : 'Already have an account? Log In'}
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProductPage;
