import React, { useEffect, useState } from 'react';
import kluret_svggg from './whitepaper_img/klure.svg';
import bg_svggg from './whitepaper_img/bg.svg';
import klure_vn from './whitepaper_img/kluret_vn.svg';
import chatwithai from './whitepaper_img/chatwithai.svg';
import productlisting from './whitepaper_img/productlisting.svg';
import moreinfor from './whitepaper_img/moreinfor.svg';
import './whitepaper_img/whitepaper.css';
import Nav from './whitepaper_img/white/nav';
import kluret_white_svg from '../__whitepaper__/images/kluret_wt.svg';
import Bottomnav from './whitepaper_img/white/compo/botom_nav';
import Omkluret from '../__whitepaper__/jsx/omkluret';

const WhitePaper = () => {
    const texts = [
        "Effortlessly search products with Kluret.",
        "Find products quickly and easily using Kluret.",
        "Experience fast, efficient product searches with Kluret.",
        "Kluret simplifies your search for products.",
        "Seamless product discovery with Kluret.",
        "Quickly locate products with Kluret.",
        "Smooth and efficient search powered by Kluret.",
        "Simplified product searches with Kluret's AI.",
        "Discover products with ease using Kluret."
    ];
    
    
    const [currentText, setCurrentText] = useState('');
    const [index, setIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(150);
    const [hasSentRequest, setHasSentRequest] = useState(false);

    useEffect(() => {
        const recordVisit = async () => {
            if (hasSentRequest) return; // Prevent multiple requests
    
            try {
                // Get the current time
                const timestamp = new Date().toISOString();
    
                // Collect platform and language information
                const platform = navigator.platform;
                const language = navigator.language;
    
                // Combine data (timestamp, platform, and language) into a string
                const dataToSend = JSON.stringify({
                    timestamp,
                    platform,
                    language,
                });
    
                // Send the data to the API
                await fetch('https://usbaiclient-bc9c07737d7c.herokuapp.com/notify/api/visitors/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: dataToSend,
                });
    
                // Mark the request as sent
                setHasSentRequest(true);
            } catch (error) {
                console.error('Error recording visit:', error);
            }
        };
    
        recordVisit();
    }, [hasSentRequest]);
    


    useEffect(() => {
        const handleTyping = () => {
            const current = texts[loopNum % texts.length];
            setCurrentText(
                isDeleting
                    ? current.substring(0, currentText.length - 1)
                    : current.substring(0, currentText.length + 1)
            );

            setTypingSpeed(isDeleting ? 0 : 150);

            if (!isDeleting && currentText === current) {
                setTimeout(() => setIsDeleting(true), 2000);
            } else if (isDeleting && currentText === '') {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
                setIndex(0);
            }
        };

        const timer = setTimeout(handleTyping, typingSpeed);
        return () => clearTimeout(timer);
    }, [currentText, isDeleting, loopNum, typingSpeed]);


    return (
        <div className="bg-black text-white min-h-screen">
            <div className='__navbar__3er8hef9'>
                <Nav />
            </div>
            <div id="ihuwisdfhoeifsyghjkesfhpgluehjsfoiewkskbfvjcpglewf" className="____ruudisjf_dem4">
                
                <div className="iframe-background">
                    <iframe src='https://my.spline.design/gitnesssplinetest-7f8433d770bdc6373cbc3623749e1390/' frameborder='0'></iframe>
                </div>

                <div className="blackspot">
                </div>

                <div className="content">
                    <div>
                        <div className="ioolghowekgurhijowgeuifghoetugifui4heogdtfg">
                            <div className="jiiyhsfiohwegsgkifhosgegsh5urj2hewhoiepeeeeepfwovkiehf text-center text-2xl font-bold">
                                <h1>{currentText}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center" id="ioolghowekgurhijowgeuifghoetugifui4heogdtfg___db">
                        <a href="https://www.kluret.se/chat" className="poihofspdherjoijkfpwoeufhpgwoseudfhopg">
                            Try Kluret →
                        </a>
                    </div>
                </div>
            </div>



            <div className="what_is_kluret">
                <div>
                    <div className="header__1dll">
                        <div className="__imgdiv__wokr4j9rf">
                            <img src={klure_vn} alt="" />
                        </div>
                        <div className="__about_kluret__e39rj9djf"id='___how_itworks__9jer4'>
                            <h1>What is Kluret</h1>
                            <p>Kluret is an AI-powered search engine built for Sweden, designed to help users efficiently find any type of product across the Swedish web. In Version 1, the AI has been trained to deliver fast and accurate results for a wide range of products, providing a tailored, localized search experience.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="how_kluretworks_demonstrations_39jre9jf">
                <div className="header__lines__de0ejf0e">
                    <div></div>
                        <h1 className='__header__e93jejdfri'>Quick Guid</h1>
                    <div></div>
                </div> 
                <div className="how_kluretworks_demonstrations_39jre9jf_holder">
                    <div className="how_kluretworks_demonstrations_39jre9jf_holder_9j4jefed">
                        <div>
                            <div className="__images__divslr494j">
                                <img src={chatwithai} alt="" />
                            </div>
                            <div className="__demonstrations__k0jef9dfj4ej">
                                <h1>Chatting with the AI</h1>
                                <p>Users can chat with Kluret’s AI to receive personalized assistance in finding the exact product they are looking for.</p>
                            </div>
                        </div>
                    </div>
                    <div className="how_kluretworks_demonstrations_39jre9jf_holder_9j4jefed">
                        <div>
                            <div className="__images__divslr494j">
                                <img src={productlisting} alt="" />
                            </div>
                            <div className="__demonstrations__k0jef9dfj4ej">
                                <h1>Products Listings</h1>
                                <p>After finding potential products, users can click the “View” button to browse, compare, and choose from a comprehensive list of product options.</p>
                            </div>
                        </div>
                    </div>
                    <div className="how_kluretworks_demonstrations_39jre9jf_holder_9j4jefed">
                        <div>
                            <div className="__images__divslr494j" id="__e9fhrjfowwur0i3hoirofjdwfnorjkld__Doek">
                                <img src={moreinfor} alt="" />
                            </div>
                            <div className="__demonstrations__k0jef9dfj4ej">
                                <h1>Know more about the Product</h1>
                                <p>Kluret’s Product Assist lets users ask questions, providing detailed, tailored information to ensure informed decisions before purchasing.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="latests_updates_section">
                <div className="header__lines__de0ejf0e">
                    <div></div>
                        <h1 className='__header__e93jejdfri'>Latest Updates</h1>
                    <div></div>
                </div> 
                <div className="__latestsposts__yet">
                    <div className="__updates__yesss">
                        <div>
                            <div className="__kluret_updates_headers__ed42ed">
                                <h1>Enhanced AI Chat Features</h1>
                                <img src={kluret_white_svg} alt="" />
                            </div>
                            <p>The AI chat functionality has been upgraded to provide even more personalized product recommendations. Users can now interact with the AI in a more conversational and intuitive way, getting quicker, more accurate suggestions for the items they're seeking.</p>
                        </div>
                    </div>
                    <div className="__updates__yesss">
                        <div>
                            <div className="__kluret_updates_headers__ed42ed">
                                <h1>Improved Product Comparison Tools</h1>
                                <img src={kluret_white_svg} alt="" />
                            </div>
                            <p>Kluret has introduced an enhanced product comparison tool within the product listings. Now, users can more easily compare specifications, prices, and reviews side-by-side, streamlining the decision-making process and helping them find the best deals.</p>
                        </div>
                    </div>
                    <div className="__updates__yesss">
                        <div>
                            <div className="__kluret_updates_headers__ed42ed">
                                <h1>Kluret Product Assist Integration</h1>
                                <img src={kluret_white_svg} alt="" />
                            </div>
                            <p>Our Product Assist feature has been further developed, allowing users to ask even more detailed questions about products before purchasing. With this update, Kluret now provides deeper insights into product functionality, quality, and alternatives, enhancing user confidence when shopping online.</p>
                        </div>
                    </div>
                </div>
                <div className='view__0jefothelatests'>
                    <a href="/latests" className='latests___osdlatests'>View All Updates →</a>
                </div>
            </div>

            <div className="__Partners__eojiffr4">
                <div className="header__lines__de0ejf0e">
                    <div></div>
                        <h1 className='__header__e93jejdfri'>Partners</h1>
                    <div></div>
                </div> 
                <div className="___partners__lists_e3de">
                    <div behavior="" direction="right" className="__animations___dd9djfr">
                        <div className="__partnerholders__edejs0r30k">
                            <div className="___partners__lists_e3de__1">
                                <div>
                                    <img src="https://www.svgrepo.com/show/475656/google-color.svg" alt="" />
                                </div>
                                <div>
                                    <p>Google Gemini</p>
                                </div>
                            </div>
                            <div className="___partners__lists_e3de__1">
                                <div>
                                    <img src="https://www.svgrepo.com/show/306500/openai.svg" alt="" />
                                </div>
                                <div>
                                    <p>OpenAI 4o</p>
                                </div>
                            </div>
                            <div className="___partners__lists_e3de__1">
                                <div>
                                    <img src="https://www.svgrepo.com/show/349358/etsy.svg" alt="" />
                                </div>
                                <div>
                                    <p>Etsy Partnerd</p>
                                </div>
                            </div>
                            <div className="___partners__lists_e3de__1">
                                <div>
                                    <img src="https://www.svgrepo.com/show/354401/stripe.svg" alt="" />
                                </div>
                                <div>
                                    <p>Stripe</p>
                                </div>
                            </div>
                            <div className="___partners__lists_e3de__1">
                                <div>
                                    <img src="https://www.svgrepo.com/show/508697/klarna.svg" alt="" />
                                </div>
                                <div>
                                    <p>Klarna Bank</p>
                                </div>
                            </div>
                            <div className="___partners__lists_e3de__1">
                                <div>
                                    <img src="https://www.svgrepo.com/show/448259/alibaba.svg" alt="" />
                                </div>
                                <div>
                                    <p>Alibaba Affiliated</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="trykluretforfree__die9hr4fo">
                <a href="/chat">Try Kluret for Free</a>
            </div>

            <Bottomnav />
        </div>
    );
};

export default WhitePaper;
