import React, { useState } from 'react';
import '../styling/letschat.css';
import '../styling/usermenu.css';
import settings_svg from '../../tamplates/images/settings.svg';
import shipping_svg from '../../tamplates/images/shipping.svg';
import product_shipping_svg from '../../tamplates/images/product_shipping.svg';
import logout_svg from '../../tamplates/images/logout.svg';
import ProfileKluret from './profile';
import CartKluret from './cart';
import TrakingKluret from './traking';
import { useNavigate } from 'react-router-dom';
import continue_google_svg from '../../tamplates/images/google.svg';

// Utility function to check if user is logged in
const isLoggedIn = () => {
    const sessionToken = localStorage.getItem('sessionToken');
    return sessionToken && JSON.parse(sessionToken).expiryTime > Date.now();
};

function Usermenu() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [overlayVisible, setOverlayVisible] = useState(false); // State for full-screen overlay
    const navigate = useNavigate(); // Define navigate for routing
    const [currentAuthDiv, setCurrentAuthDiv] = useState('LogIn');
    const [currentView, setCurrentView] = useState('menu'); // State for current view

    const handleLogin = async () => {
        try {
            const response = await fetch('https://usbaiclient-8c59f2a693e9.herokuapp.com/users/login_authorizer/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                if (data.status === "success") {
                    // Set session token in localStorage with expiry time
                    const expiryTime = Date.now() + 10000000000000; // 10 seconds from now (example)
                    localStorage.setItem('sessionToken', JSON.stringify({ token: data.token, expiryTime }));

                    // Store user ID in local storage
                    localStorage.setItem('userID', data.user_id);

                    // Redirect to /

                    // Alert user ID
                    alert(`User ID: ${data.user_id}`);
                } else {
                    setErrorMessage(data.message || 'Incorrect password');
                }
            } else {
                setErrorMessage(data.message || 'User not found');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setErrorMessage('Failed to log in. Please try again.');
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (email === '' || password === '' || confirmPassword === '') {
            setMessage('All fields are required');
            return;
        }

        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        const userData = {
            email: email,
            password: password,
        };

        try {
            const response = await fetch('https://usbaiclient-8c59f2a693e9.herokuapp.com/users/register_user/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage(data.message);
            } else {
                setMessage(data.message || 'Failed to register user');
            }
        } catch (error) {
            setMessage('Failed to connect to server');
        }
    };

    const handleLogout = () => {
        if (isLoggedIn()) {
            localStorage.removeItem('sessionToken');
            window.location.href = '/chat';
        } else {
            setOverlayVisible(true);
        }
    };

    const handleToggleProfile = () => {
        if (isLoggedIn()) {
            setCurrentView('profile');
        } else {
            setOverlayVisible(true);
        }
    };

    const handleToggleCart = () => {
        if (isLoggedIn()) {
            setCurrentView('cart');
        } else {
            setOverlayVisible(true);
        }
    };

    const handleToggleTracking = () => {
        if (isLoggedIn()) {
            setCurrentView('tracking');
        } else {
            setOverlayVisible(true);
        }
    };

    const handleCloseOverlay = () => {
        setOverlayVisible(false);
    };

    const handleBackToMenu = () => {
        setCurrentView('menu');
    };

    const renderLogin = () => (
        <div>
            <h1>LogIn</h1>
            <div className='jihugifguhiojppihugyft'>
                <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <br />
            </div>
            <div className='kjkjhgjhljoihugyf' onClick={(e) => { handleLogin(); handleCloseOverlay(e); }}>
                <a className='bhvjgciyioipufyut'>
                    LogIn
                </a>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
    );

    const renderSignup = () => (
        <div>
            <h1>SignUp</h1>
            <div className='jihugifguhiojppihugyft'>
                <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <input
                    type="password"
                    placeholder="Create Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <br />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <br />
            </div>
            <div className='kjkjhgjhljoihugyf' onClick={(e) => { e.preventDefault(); handleRegister(e); setCurrentAuthDiv('LogIn'); }}>
                <a className='bhvjgciyioipufyut'>
                    SignUp
                </a>
            </div>
            {message && <p className="error-message">{message}</p>}
        </div>
    );

    return (
        <>
            {/* {currentView === 'menu' && (
                <div className='iClmgqJW6vPMF8VXY4HTb2Dx'>
                    <div className='iohuifygrifhuw'>
                        <div className='doiygusifugusrwri'>
                            <div onClick={handleToggleProfile}>
                                <img src={settings_svg} alt="profile" />
                                <p>Profile Settings</p>
                            </div>
                            <div onClick={handleToggleCart}>
                                <img src={shipping_svg} alt="cart" />
                                <p>Shopping Cart</p>
                            </div>
                            <div onClick={handleToggleTracking}>
                                <img src={product_shipping_svg} alt="tracking" />
                                <p>Track My Order</p>
                            </div>
                            <div onClick={handleLogout}>
                                <img src={logout_svg} alt="logout" />
                                <p>LogOut</p>
                            </div>
                        </div>
                    </div>


                    <p>The Engine is under development</p>
                </div>
            )} */}

            {/* {currentView === 'profile' && (
                <div className='profile-section'>
                    <ProfileKluret />
                    <button className='close-button' onClick={handleBackToMenu}>Close</button>
                </div>
            )}
            {currentView === 'cart' && (
                <div className='cart-section'>
                    <CartKluret />
                    <button className='close-button' onClick={handleBackToMenu}>Close</button>
                </div>
            )}
            {currentView === 'tracking' && (
                <div className='tracking-section'>
                    <TrakingKluret />
                    <button className='close-button' onClick={handleBackToMenu}>Close</button>
                </div>
            )} */}

            {/* Full-Screen Overlay for Not Logged In */}
            {overlayVisible && (
                <div className='fokpuiysughijok4pektjif'>
                    <div className='ijhuyguop53908rhe39859rugd'>
                        <div className='jpiougiyfutygihojpjihugyft7u'>
                            {currentAuthDiv === 'LogIn' ? renderLogin() : renderSignup()}
                            <div>
                                <button onClick={() => setCurrentAuthDiv('LogIn')}>LogIn</button>
                                <button onClick={() => setCurrentAuthDiv('SignUp')}>SignUp</button>
                            </div>
                        </div>
                        <div className='ohuiyw085hrgdjsuhh493hrgdi'>
                            <div></div>
                            <div className='oppiufbuhi4hrn'>
                                <button onClick={handleCloseOverlay}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Usermenu;
